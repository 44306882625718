@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

body[data-C2PTheme="bolttech"] {
  --brand-primary: #00BAC7;
  --brand-primary600: #33C8D2;
  --brand-primary300: #CCF1F4;
  --brand-secondary: #170f4f;
  --brand-secondary400: #A29FB9;
  --brand-lightSecondary: #453f72;
  --brand-lightSecondary500: #746f95;
  --brand-lightGrey: #e5e5e5;
  --brand-lightGrey100: #f3f3f6;
  --brand-darkGrey: #e8e7ee;
  --brand-white: #fff;
  --brand-black: #000;
  
  // GENERAL
  --fontStack: "Roboto", sans-serif;

  --input-radius: 0px;
  --input-borderColor: var(--brand-lightSecondary);

  --app-BG: var(--brand-lightGrey100);
  --app-textColor: var(--brand-secondary);

  --h2-fontWeight: 300;
  --h2-fontSize: 24px;

  --h4-fontWeight: 500;
  --h4-fontSize: 14px;

  --h5-fontWeight: 500;
  --h5-fontSize: 12px;

  --para-fontWeight: 400;
  --para-fontSize: 14px;

  --button-fontWeight: 500;
  --button-fontSize: 14px;

  // Header
  --header-BGColor: var(--brand-white);
  --header-text: var(--brand-secondary);

  // C2P Button
  --c2pButton-borderRadius: 0px;
  --c2pButton-primary-BGcolor: var(--brand-secondary);
  --c2pButton-primary-textColor: var(--brand-white);
  --c2pButton-primary-borderColor: var(--brand-secondary);
  --c2pButton-primary-disabledBGColor: var(--brand-secondary400);
  --c2pButton-secondary-BGcolor: transparent;
  --c2pButton-secondary-textColor: var(--brand-secondary);
  --c2pButton-secondary-borderColor: var(--brand-secondary);
  --c2pButton-link-BGcolor: var(--brand-white);
  --c2pButton-link-borderColor: var(--brand-white);
  --c2pButton-link-textColor: var(--brand-secondary);
  --c2pButton-link-disabledTextColor: #D1CFDC;

  // Radio Button toggle
  --radioButtonToggle-color: var(--brand-secondary);
  --radioButtonToggle-BGcolor: #ccf1f4;
  --radioButtonToggle-borderColor: #d1cfdc;

  // Form Select box
  --formSelectBox-secondaryBorderColor: var(--brand-secondary);
  --formSelectBox-secondaryColor: var(--brand-secondary);

  // Sidebar
  --sidebar-bGcolor: var(--brand-secondary);
  --sidebar-textColor: var(--brand-white);

  // Transaction Table
  --filters-label: var(--brand-lightSecondary);
  --transactionTable-color: var(--brand-secondary);
  --transactionTable-tableHeaderBG: var(--brand-darkGrey);
  --transactionTable-listDataColor: var(--brand-lightSecondary);
  --transactionList-tableHeading: var(--brand-secondary);
  --transactionList-tableCell: var(--brand-secondary);

  // Single Transaction
  --singleTxn-textColor: var(--brand-secondary);
  --singleTxn-textColorSecondary: var(--brand-lightSecondary);
  --singleTxn-textColorSecondary500: var(--brand-lightSecondary500);
  --singleTxn-reflectionBoxBG: var(--brand-white);
  --singleTxn-imageBG: var(--brand-white);

  // Toast Notification
  --toast-BGColor: var(--brand-primary300);
  --toast-borderColor: var(--brand-primary300);
  --toast-textColor: var(--brand-secondary);
  --toast-iconColor: var(--brand-primary600);  
  
}
