.c2pButton {
  border-radius: 0px;
  font-weight: 500;
  font-size: 14px;
}

.secondaryCTA {
  background-color: var(--c2pButton-secondary-BGcolor) !important;
  border-color: var(--c2pButton-secondary-borderColor) !important;
  color: var(--c2pButton-secondary-textColor) !important;
}

.linkCTA {
  text-decoration: none;
}
