.headerContainer {
  background-color: var(--header-BGColor);

  .iconContainer {
    cursor: pointer;

    svg {
      height: 24px;
    }
  }
}
