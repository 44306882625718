.RadioButtonGroup{
  .radioButton{
    margin-top: 0px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 0px;
    color: var(--radioButtonToggle-color) !important;
    background-color: transparent !important;
    border: 1px solid var(--radioButtonToggle-borderColor) !important;
    padding: 12px 16px !important;
  }
  .radioButton.active{
    border: 1px solid var(--radioButtonToggle-borderColor) !important;
    color: var(--radioButtonToggle-color) !important;
    background-color: var(--radioButtonToggle-BGcolor) !important;
  }
}