.toastNotificationContainer{
    .toastMain{
        background-color: var(--toast-BGColor);
        border-color: var(--toast-borderColor);
        color: var(--toast-textColor);
        border-radius: 0px;

        img{
            background-color: var(--toast-iconColor);
        }

        .toastDescription{
            font-size: 12px;
            line-height: 16px;
        }
    }
}