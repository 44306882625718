.singleTransactionPage {
  color: var(--singleTxn-textColor);
  .mandatoryField{
    color: red;
  }
  .breadcrumbs {
    p {
      color: var(--singleTxn-textColorSecondary500);

      .trxnID {
        color: var(--singleTxn-textColor);
      }
    }
  }

  .guideline{
    
  }

  .imageDetails {
    h5 {
      color: var(--singleTxn-textColorSecondary500);
    }

    p {
      font-weight: 300;
    }

    .reflectionScoreContainer{
      .scoreContainer{
        background-color: var(--singleTxn-reflectionBoxBG);
        flex: 1;
        h4{
          margin: 0px;
        }
        .scoreHeading{
          color: var(--singleTxn-textColorSecondary500);
        }
      }
    }    

    textarea{
      border: unset;
      border-bottom: 1px solid var(--input-borderColor);
    }
  }

  .boundingBoxToggleSwitch {
    input {
      border-radius: 0px;
      padding: 10px 20px;
      margin-right: 12px;
      background-color: #A29FB9;
      border-color: #A29FB9;
    }

    label {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .c2pBehaviorField {
    input[type=checkbox] {
      border-radius: 0px;
      border-color: var(--singleTxn-textColor);
    }

    label {
      font-weight: 300;
    }
  }

}
.submitButton {
  position: fixed;
  bottom: 0;
  background-color: white;
  width: inherit;
  z-index: 2;
  box-shadow: 0px 4px 12px 0px #0000001A;

  p{
    color: var(--singleTxn-textColorSecondary500);
  }
}