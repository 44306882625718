.deviceImageSection{
  .spinner {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 25%;
  }

  .predictionsBottom{
    
  }

  .deviceImageContainer{
    padding: 0px 25%;
    border-radius: 4px;
    background-color: var(--singleTxn-imageBG);
  }

  .imageOverlayCanvas{
    z-index: -1;
    left: 25%;
  }
}

