@import "./themes/bolttech/bolttech.style";
@import "./themes/ORANGE_FR/_ORANGE_FR.style";

@import "./customUI.scss";

body {
  font-family: var(--fontStack) !important;
  background-color: var(--app-BG);
  color: var(--app-textColor);
}

input {
  border-radius: var(--input-radius);
  border: none;
}

select {
  border-radius: var(--input-radius);
  border: none;
}

label {
  font-size: 14px;
  font-weight: 400;
}

h2 {
  font-weight: var(--h2-fontWeight);
  font-size: var(--h2-fontSize);
  line-height: 24px;
}

h4 {
  font-weight: var(--h4-fontWeight);
  font-size: var(--h4-fontSize);
  line-height: 24px;
}

h5 {
  font-weight: var(--h5-fontWeight);
  font-size: var(--h5-fontSize);
  line-height: 16px;
}

p {
  font-weight: var(--para-fontWeight);
  font-size: var(--para-fontSize);
  line-height: 20px;
}

button {
  font-weight: var(--button-fontWeight);
  font-size: var(--button-fontSize);
}
