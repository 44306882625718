.loginWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  width: 100%;
  height: 80vh;

  .loginWrapperInner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all 0.3s;
  }

  h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }

  .alert {
    text-align: center;

    .alertHeading {
      font-weight: bold;
    }
  }
}
