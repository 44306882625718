$secondary-navy: #170f4f;
$secondary-grey: #a29fb9;
$box-background: #f9f9fb;
$box-heading-color: #453f72;

.downloadButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.reportContainer {
  background-color: white;
  padding-bottom: 40px;
  font-style: normal;

  .reportDownloadSection:first-child {
    padding-top: 64px;
  }
  .reportDownloadSection {
    padding: 20px 64px;
  }

  .dateSeparator {
    margin: 0 8px;
  }

  .reportHeading {
    font-weight: 700;
    font-size: 44px;
    color: $secondary-navy;

    img {
      max-height: 60px;
    }
  }

  .tenantName {
    font-size: 24px;
    margin: 8px 0;
    color: $secondary-navy;
  }

  .dateRange {
    color: $secondary-grey;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 40px;
  }

  table {
    background-color: $box-background;
    padding: 16px;
    color: $secondary-navy;
    border-radius: 4px;

    td,
    th {
      padding: 16px;
      text-align: left !important;
      width:120px;
      vertical-align: middle;
    }

    th:first-child {
      width: 25%;
    }

    th:last-child {
      width: 40%;
    }

    td:last-child {
      padding-right: 16px;
      text-align: center;
    }
  }

  td > span {
    margin-left: 8px;
    color: $secondary-navy;
    font-weight: 600;
    font-size: 16px;
  }

  .dataRow {
    display: flex;

    > div {
      padding: 16px;
      background-color: $box-background;
      border-radius: 4px;
      margin-right: 8px;
      flex: 1;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }

      .svgImage {
        margin-right: 8px;
      }

      .heading {
        font-weight: 500;
        font-size: 14px;
        color: $box-heading-color;
      }

      .content {
        margin-top: 8px;
        color: $secondary-navy;
        font-weight: 500;
        font-size: 40px;
      }

      .conversionRateData {
        font-size: 13px;
      }
    }
  }

  .sectionHeading {
    margin-top: 40px;
    margin-bottom: 16px;
    h5 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      font-weight: 300;
    }
  }

  .lastRow {
    display: flex;

    div:nth-child(1) {
      margin-right: 16px;
    }

    .sectionContainer {
      flex: 1;
    }
  }

  progress {
    display: inline-block;
    width: 100px;
    height: 8px;
    border-radius: 16px;
  }

  progress::-webkit-progress-bar {
    background-color: #d1cfdc;
  }

  progress::-webkit-progress-value {
    background: #453f72;
  }
}

.svgImage {
  margin-left: 4px;
  margin-right: 14px;
  height: 16px;
  width: 16px;
}

.allOption {
  margin-right: 26px;
}
