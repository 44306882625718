.trxnListTable {
  .trxnStatusPill {
    background: #d1cfdc;
    border-radius: 4px;
    padding: 4px 8px;
    color: var(--transactionTable-listDataColor);
  }
}

.noDataFound {
  font-weight: 300;
  color: var(--transactionTable-color);
}
