.filterContainer {
  label.filterLabel {
    color: var(--filters-label);
    margin-bottom: 8px;
    font-size: 12px;
  }

  .tenantDropDown {
    border-bottom: 1px solid var(--input-borderColor);
  }
}

.dateContainer {
  .datePickerCustomStyle {
    border-bottom: 1px solid var(--input-borderColor);
  }

  input {
    padding: 12px 10px;
    width: 100%;
  }

  .dateIconContainer {
    position: absolute;
    top: 40px;
    right: 12px;
  }
}

.buttonContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .reportSelectContainer {
    width: 30%;
  }
}

.dataTable {
  thead {
    background-color: var(--transactionTable-tableHeaderBG);

    th {
      font-size: 12px;
      font-weight: 500;
      vertical-align: baseline;
      color: var(--transactionList-tableHeading);
    }
  }

  tbody {
    tr {
      background-color: white;

      td {
        font-size: 14px;
        padding: 15px 10px;
        color: var(--transactionTable-listDataColor);

        a {
          color: var(--transactionList-tableCell);
          text-decoration: none;
        }
      }
    }
  }
}
