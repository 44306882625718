.guidelineModalPopup{
    .guidelineModalTitle{
        font-size: 24px;
        font-weight: 400;
    }
    .guidelineModalBody{
        font-weight: 300;
        .listHeading{
            font-size: 14px;
        }
        ol{
            li{
                font-size: 14px;
                b{
                    font-weight: 700;
                }
                line-height: 20px;
            }
        }
    }
}