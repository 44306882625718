.toggleContainer {
  display: flex;
  align-items: center;

  input[type="checkbox"]{
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='6' height='6' fill='white'/%3E%3C/svg%3E%0A") !important;
  }
}
.label {
  padding-right: 8px;
}
