.formSelectBoxPrimary {
	border: none;
}

.formSelectBoxSecondary {
	border: 1px solid var(--formSelectBox-secondaryBorderColor);

	.c2p-react-select__indicators {
		.c2p-react-select__indicator-separator {
			background-color: unset;
		}

		svg {
			color: var(--formSelectBox-secondaryColor);
		}
	}
}

.c2p-react-select__control {
	.c2p-react-select__placeholder {
		color: var(--formSelectBox-secondaryColor);
	}
}