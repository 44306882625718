body[data-C2PTheme="orange_FR"] {
  --brand-primary: #f27405;
  --brand-secondary: #000;
  --brand-white: #fff;
  --brand-black: #000;

  --header-BGColor: var(--brand-secondary);
  --header-text: var(--brand-white);

  --c2pButton-primary-BGcolor: var(--brand-primary);
  --c2pButton-primary-textColor: var(--brand-white);
}
