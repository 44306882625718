.tenantLogoContainer {
  max-height: 65px;

  .logo {
    margin-left: 32px;
    margin-right: 32px;

    svg {
      max-width: 150px;

      path {
        fill: white;
      }
    }
  }
}

.sidebarMenu {
  margin-top: 60px;

  .menuItem {
    font-size: 14px;
    font-weight: 500;

    svg {
      margin-right: 20px;

      path {
        fill: white;
        width: 24px;
      }
    }

    a {
      color: white;
      text-decoration: none;
    }
  }
}
