$toast-max-width: 480px;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

.btn-primary{
    --bs-btn-disabled-bg: var(--c2pButton-primary-disabledBGColor);
    --bs-btn-disabled-border-color: var(--c2pButton-primary-disabledBGColor);
    --bs-btn-bg: var(--c2pButton-primary-BGcolor);
    --bs-btn-border-color: var(--c2pButton-primary-borderColor);
    --bs-btn-color: var(--c2pButton-primary-textColor);
    --bs-btn-hover-bg: var(--c2pButton-primary-BGcolor);
}

.btn-link{
    --bs-btn-disabled-color: var(--c2pButton-link-disabledTextColor);
    --bs-btn-color: var(--c2pButton-link-textColor);
    --bs-btn-bg: var(--c2pButton-link-BGcolor);
    --bs-btn-hover-color: var(--c2pButton-link-textColor);
}